<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="goBack" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div v-if="fileItems.length > 0" class="header-column header-util right">
        <div class="util util-download">
          <a href="javascript:" @click="isFileAtchListShow = true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{items.ntcTitle}} </h3>
            <p class="subtitle"><span class="text">공지</span><span class="text">{{ getDateFormat('yyyy.MM.dd hh:mm', items.regDt) }} 등록</span>
              <span class="text">{{items.inqCnt}} 조회</span></p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content" v-html="items.ntcCn"/>
          <div v-if="fileItems.length > 0" class="segment-box p-3">
            <strong class="text-lg">첨부파일</strong>
            <ul>
              <li v-for="(item, idx) in fileItems" :key="idx" class="mt-1">
                <a @click.stop="downloadFile(item)" class="kb-mouse-cursor">
                  <span class="link-dark">{{item.fileNm}}</span>
                  <span class="text-black-50 ps-3">{{ convertToStorageBytes(item.fileSz)}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <HelpMobileNoticeFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="fileItems"/>
</template>

<script>

// import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_NOTICE, ACT_UPDATE_NOTICE_CNT} from '@/store/modules/help/help';
import {commonDownloadFile, getItem, getItems, lengthCheck, timestampToDateFormat, convertToStorageBytes} from '@/assets/js/util';
import router from "@/router";
import HelpMobileNoticeFileAtch from "@/views/pages/help/mobile/HelpMobileNoticeFileAtch";

export default {
  name: 'HelpMobileNoticeView',
  components: {HelpMobileNoticeFileAtch},
  // components: {LxpMainHeader},
  setup(){

    const store = useStore();
    const route = useRoute();

    const noticeTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);
    const showAttachments = ref(false);
    const comNtcSn = computed(() => route.params.ntcSn);
    const isFileAtchListShow = ref(false);

    const getHelpNotice = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE}`,
          route.params.ntcSn
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          getHelpNoticeFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getHelpNotice();

    const getHelpNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comNtcSn: comNtcSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (comNtcSn.value > 0) {
        await store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {comNtcSn: comNtcSn.value});
      }
    };

    const downloadFile = (atch) => {
      commonDownloadFile(atch);
    }

    onMounted(() => {
      pageViewCnt();
    });

    const goBack = () => {
      router.go(-1);
    }

    watch(() => route.params.ntcSn, ()=>{
      getHelpNotice();
    });

    return {
      noticeTitle,
      items,
      fileItems,
      showAttachments,
      isFileAtchListShow,
      goBack,
      getDateFormat,
      downloadFile,
      convertToStorageBytes,
    }

  }
}
</script>